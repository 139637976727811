/**
 * 执行记录详情
 * @type {{}}
 */
import Vue from "vue";
import { Input, Icon, Select, Option, Message, Table, Page, Modal,Button,Tabs,TabPane,Spin,Poptip,Drawer,Dropdown,DropdownMenu,DropdownItem,Breadcrumb,BreadcrumbItem } from "view-design";
import {
    recordInfoColumns,
    recordModalListColumns,
    getRecordInfoService,
    getImageUrlService,
    getRecordVideoListService, batchInputRecordColumns
} from "@/service/workflow-service";
import { mapState } from "vuex";
Vue.component('Input', Input);
Vue.component('Icon', Icon);
Vue.component('Select', Select);
Vue.component('Option', Option);
Vue.component('Table', Table);
Vue.component('Page', Page);
Vue.component('Modal', Modal);
Vue.component('Button', Button);
Vue.component('Tabs', Tabs);
Vue.component('TabPane', TabPane);
Vue.component('Spin', Spin);
Vue.component('Poptip', Poptip);
Vue.component('Drawer', Drawer);
Vue.component('Dropdown', Dropdown);
Vue.component('DropdownMenu', DropdownMenu);
Vue.component('DropdownItem', DropdownItem);
Vue.component('Breadcrumb', Breadcrumb);
Vue.component('BreadcrumbItem', BreadcrumbItem);
import ViewImage from '@/components/ViewImage/viewImage.vue'
const RecordInfo = {
    name:'RecordInfo',
    components:{ ViewImage },
    computed: {
        ...mapState({
            taskId: state => state.system.taskId, //任务id
            taskList: state => state.system.taskList, //任务数据
        }),
    },
    data() {
        return {
            recordLoading:false, //loading
            recordVideoListLoading:false, //loading
            recordVideoLoading:false, //loading
            recordModalVisible:false, //图片modal
            voiceModalVisible:false,
            recordModalListVisible:false, //批量操作查看记录弹框
            batchInputListVisible:false, //批量输入查看记录弹框
            recordModalTitle:'', //modal title
            recordInfoColumns:recordInfoColumns, //显示字段
            recordModalListColumns:recordModalListColumns, //批量操作记录列表显示字段
            batchInputRecordColumns:batchInputRecordColumns, //批量输入显示数据
            recordInfoArray:[], //数据集合
            recordSelectObj:{}, //选择的视频数据
            recordVideoArray:[], //视频列表数据
            recordInfoVisible:false, //任务信息弹框可显示
            stepFeedbackVisible:false, //步骤反馈弹框可显示
            quickFeedbackVisible:false, //快捷反馈弹框可显示
            viewImageVisible:false,
            imageName:'',
            quickList:[],
            voiceList:[],
            videoList:[],
            voiceInputKey:'',
            audioUrl:'',
            quickNumber:1,
            playIndex:0,
            recordInfoObj:[], //任务信息
            videoKey:0,
            imageSrc:'',
            videoSrc:'',
            stepFeedbackObj:{},
            audioSrc:'', //语音文件地址
            audioText:'', //语音文件文本
            audioVisible:true,
            inputTypeCode:'', //输入类型
            unitType:'', //单位
            batchArray:[], //批量操作数据集
            batchInputArray:[], //批量输入数据集
            recordInfoParams:{ //提交的数据集
                currentPage: 1,  //当前页
                displayNumber:20 // 显示条数
            }
        }
    },
    created() {
        this.onRecordInfo().then()
    },
    methods: {
        /**
         * 返回
         */
        onBack() {
            this.$router.push('/workflow/record/list')
        },
        /**
         * 监听播放状态
         */
        onPlayStatus() {
            this.voiceList[this.voiceInputKey].playStatus = false
            this.$forceUpdate()
        },
        onVisiblePlayChange(status){
            if(!status && this.voiceList.length!==0){
                this.$refs.audioPlayer.removeEventListener('ended', this.onPlayStatus,false);
            }
        },
        /**
         * 点击选项卡
         * @param n
         */
        onTabsClick(n){
            if(n === 1){
                this.onRecordVideoListService().then()
            }
        },
        /**
         * 查看任务信息
         * @param row
         */
        onRecordInfoRow(row){
            this.recordInfoVisible = true
            this.recordInfoObj = row.stepContentList
        },
        /**
         * 展开收起
         * @param key
         */
        onRecordVisible(key){
            this.recordInfoArray[key].visible = !this.recordInfoArray[key].visible
            this.$forceUpdate()
        },
        /**
         * 选择的视频数据
         * @param obj
         */
        onSelectVideo(obj,key){
            this.videoSrc = ''
            this.recordSelectObj = obj
            this.videoKey = key+1
            this.$nextTick(()=>{
                this.videoSrc = obj.videoFileUrl
            })
        },
        /**
         * 查看图片
         */
        onImageAmplify(url) {
            this.recordModalTitle = '图片'
            this.recordModalVisible = true
            this.imageSrc = url
        },
        /**
         * 触发条件验证
         * @param type
         */
        onTriggerCheck(type) {
            if(type.operationType === 0){
                this.onImageAmplify(type.followFileUrl)
            }
            if(type.operationType === 1){
                this.voiceModalVisible = true
                this.audioSrc = type.followFileUrl
                this.audioText = type.feedbackContent
            }
        },
        /**
         * 步骤反馈
         * @param row
         */
        onStepFeedback(row) {
            this.stepFeedbackVisible = true
            this.quickNumber = 1
            this.stepFeedbackObj = row
            if(this.stepFeedbackObj.nodeNextTriggerList){
                let obj = {}
                for(let i in this.stepFeedbackObj.nodeNextTriggerList){
                    if(this.stepFeedbackObj.nodeNextTriggerList[i].operationType === 0){
                        obj = this.stepFeedbackObj.nodeNextTriggerList[i]
                        this.stepFeedbackObj.nodeNextTriggerList.splice(i,1)
                    }
                }
                this.stepFeedbackObj.nodeNextTriggerList.unshift(obj)
            }
        },
        /**
         * 快捷反馈
         * @param row
         * @param status
         * @param batch
         */
        onQuickFeedback(row,status,batch){
            this.quickFeedbackVisible = true
            this.quickNumber = 1
            console.log(row)
            if(!batch){
                if(row.stepFeedbackList.filter(item=>item.dataType === 1 && item.batchId === '').length !==0 && status === 'camera'){
                    this.quickList = row.stepFeedbackList.filter(item=>item.dataType === 1)
                    this.voiceList = []
                    this.videoList = []
                }else{
                    this.quickList = []
                }
                if(row.stepFeedbackList.filter(item=>item.dataType === 2 && item.batchId === '').length !==0 && status === 'video'){
                    this.videoList = row.stepFeedbackList.filter(item=>item.dataType === 2)
                    this.voiceList = []
                    this.quickList = []
                }else{
                    this.videoList = []
                }
                if(row.stepFeedbackList.filter(item=>item.dataType === 3 && item.batchId === '').length !==0 && status === 'mic'){
                    this.voiceList = row.stepFeedbackList.filter(item=>item.dataType === 3)
                    this.quickList = []
                    this.videoList = []
                    for(let i in this.voiceList){
                        this.voiceList[i].visible = true
                        this.voiceList[i].playStatus = false
                    }
                    this.$nextTick(()=>{
                        this.$refs.audioPlayer.addEventListener('ended', this.onPlayStatus,false);
                    })
                }else{
                    this.voiceList = []
                }
            }else{
                if(row.stepFeedbackList.filter(item=>item.dataType === 1 && item.batchId === batch.batchId).length !==0 && status === 'camera'){
                    this.quickList = row.stepFeedbackList.filter(item=>item.dataType === 1 && item.batchId === batch.batchId)
                    this.voiceList = []
                    this.videoList = []
                }else{
                    this.quickList = []
                }
                if(row.stepFeedbackList.filter(item=>item.dataType === 2 && item.batchId === batch.batchId).length !==0 && status === 'video'){
                    this.videoList = row.stepFeedbackList.filter(item=>item.dataType === 2 && item.batchId === batch.batchId)
                    this.voiceList = []
                    this.quickList = []
                }else{
                    this.videoList = []
                }
                if(row.stepFeedbackList.filter(item=>item.dataType === 3 && item.batchId === batch.batchId).length !==0 && status === 'mic'){
                    this.voiceList = row.stepFeedbackList.filter(item=>item.dataType === 3 && item.batchId === batch.batchId)
                    this.quickList = []
                    this.videoList = []
                    for(let i in this.voiceList){
                        this.voiceList[i].visible = true
                        this.voiceList[i].playStatus = false
                    }
                    this.$nextTick(()=>{
                        this.$refs.audioPlayer.addEventListener('ended', this.onPlayStatus,false);
                    })
                }else{
                    this.voiceList = []
                }
            }
        },
        onVoiceList(key) {
            this.voiceList[key].visible = !this.voiceList[key].visible
            this.$forceUpdate()
        },
        /**
         * 播放触发录音
         * @param url
         */
        onPlay(url,key) {
            this.audioUrl = url
            this.voiceInputKey = key
            this.$nextTick(()=>{
                for(let i in this.voiceList){
                    this.voiceList[i].playStatus = false
                }
                if(this.$refs.audioPlayer.paused){
                    this.voiceList[key].playStatus = true
                    this.$refs.audioPlayer.play()
                }else{
                    this.voiceList[key].playStatus = false
                    this.$refs.audioPlayer.pause()
                }
                this.$forceUpdate()
            })
        },
        /**
         * 上一页
         */
        onPreviousPage(status) {
            if(this.quickNumber > 1 && this.quickNumber <= (status === 'image'?this.quickList.length:this.videoList.length)){
                this.quickNumber --
            }else{
                this.quickNumber = (status === 'image'?this.quickList.length:this.videoList.length)
            }
        },
        /**
         * 下一页
         */
        onNextPage(status) {
            if(this.quickNumber >= 1 && this.quickNumber < (status === 'image'?this.quickList.length:this.videoList.length)){
                this.quickNumber ++
            }else{
                this.quickNumber = 1
            }
        },
        /**
         * 拉取节点资源文件
         * @param row
         */
        onNodeHandle(row) {
            if(row.workFileType === 'image'){
                this.onImageAmplify(row.workFileUrl)
            }
            if(row.workFileType === 'voice'){
                this.voiceModalVisible = true
                this.audioSrc = row.workFileUrl
                this.audioText = row.operationContent
                this.inputTypeCode = row.inputTypeCode
                this.unitType = row.contentUnit
            }
        },
        onLookImage(src){
            this.viewImageVisible = true
            this.imageName = this.recordInfoObj.filter((item)=>item.type === 'text')[0].content
            this.imageSrc = src
        },
        onLookMuchImage(){
            this.viewImageVisible = true
            this.imageName = '照片'
            this.imageSrc = this.quickList[this.quickNumber-1].dataResourceUrl
        },
        /**
         * 查看批量操作
         * @param list
         */
        onBatchLook(list){
            this.recordModalListVisible = true
            this.batchArray = list
        },
        /**
         * 查看批量输入
         * @param list
         */
        onBatchInputLook(list){
            this.batchInputListVisible = true
            this.batchInputArray = list
        },
        /**
         * 发送当前页
         * @param number
         */
        onSearchPage(number){
            this.recordInfoParams.currentPage = number
            this.onRecordInfo().then()
        },
        /**
         * 发送每页显示的条数
         * @param number
         */
        onSearchPageSize(number){
            this.recordInfoParams.displayNumber = number
            this.onRecordInfo().then()
        },
        /**
         * 语音组件关闭弹框时调用
         * @param status
         */
        onVisibleChange(status){
            if(!status){
                let audio = document.getElementById('audio')
                audio.pause()
                this.inputTypeCode = ''
                this.unitType = ''
            }
        },
        /**
         * 查询执行记录详情
         */
        async onRecordInfo() {
            try {
                this.recordLoading = true
                this.recordInfoArray = []
                let params = {
                    id:this.taskList.id,
                }
                const { code,data } = await getRecordInfoService(params)
                if(code === 'SUCCESS'){
                    this.recordInfoArray = data.executionRecordList
                    for(let i in this.recordInfoArray){
                        this.recordInfoArray[i].visible = true
                        if(this.recordInfoArray[i].nodeCode === 'NODE_PHOTO'){
                            this.recordInfoArray[i].stepFeedbackList.push({
                                batchId:'',
                                dataType:1,
                                dataResourceUrl: this.recordInfoArray[i].nodeDataImageUrl
                            })
                        }
                        if(this.recordInfoArray[i].nodeCode === 'NODE_VOICE_INPUT'){
                            this.recordInfoArray[i].stepFeedbackList.push({
                                batchId:'',
                                dataType:3,
                                dataResourceUrl: this.recordInfoArray[i].nodeDataRecordingUrl,
                                recordingTextContent:this.recordInfoArray[i].nodeDataRecordingText,
                                createTime:this.recordInfoArray[i].createTime
                            })
                        }
                        if(this.recordInfoArray[i].nodeCode === 'NODE_BATCH_INPUT'){
                            for(let j in this.recordInfoArray[i].batchInputList){
                                this.recordInfoArray[i].stepFeedbackList.push({
                                    batchId:this.recordInfoArray[i].batchInputList[j].batchId,
                                    dataType:3,
                                    dataResourceUrl: this.recordInfoArray[i].batchInputList[j].recordingFileResourceUrl,
                                    recordingTextContent:this.recordInfoArray[i].batchInputList[j].recordingTextContent,
                                    createTime:this.recordInfoArray[i].batchInputList[j].createTime
                                })
                            }
                        }
                    }
                    console.log(this.recordInfoArray)
                }else{
                    Message.error('请求异常，请联系管理员！')
                }
                this.recordLoading = false
            }catch (error){
                this.recordLoading = false
                console.log(error)
            }
        },
        /**
         * 查询执行记录视频列表
         */
        async onRecordVideoListService() {
            try {
                this.recordVideoListLoading = true
                let params = {
                    executionRecordId:this.taskId,
                }
                const { code,data } = await getRecordVideoListService(params)
                if(code === 'SUCCESS'){
                    this.recordVideoArray = data
                }else{
                    Message.error('请求异常，请联系管理员！')
                }
                this.recordVideoListLoading = false
            }catch (error){
                this.recordVideoListLoading = false
                console.log(error)
            }
        },
        /**
         * 拉取资源文件
         * @returns {Promise<void>}
         */
        async onResourcesUrl(id) {
            try {
                let params = {
                    id:id,
                }
                const {code,data} = await getImageUrlService(params);
                if(code === 'SUCCESS'){
                    if(data.fileType === 'wav'){
                        this.voiceModalVisible = true
                        this.audioSrc = data.url
                    }else{
                        this.recordModalTitle = '图片'
                        this.recordModalVisible = true
                        this.imageSrc = data.url
                    }
                }else{
                    Message.error('拉取上传参数出错，请稍后再试！')
                }
            }catch (error) {
                console.log(error)
            }
        },
        /**
         * 拉取资源视频
         * @returns {Promise<void>}
         */
        async onVideoUrl(id) {
            try {
                this.recordVideoLoading = true
                this.videoSrc = ''
                let params = {
                    id:id,
                }
                const {code,data} = await getImageUrlService(params);
                if(code === 'SUCCESS'){
                    this.videoSrc = data.url
                }else{
                    Message.error('拉取上传参数出错，请稍后再试！')
                }
                this.recordVideoLoading = false
            }catch (error) {
                this.recordVideoLoading = false
                console.log(error)
            }
        },
    },
    destroyed () {
    }
}
export default RecordInfo
